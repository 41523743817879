.App {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to right bottom, black, rgba(0, 0, 0, 0.830));
  overflow: hidden;
}
.m {
  margin: 30px;
}
.nav {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: max-content;
  justify-content: space-around;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  position: fixed;
  right: -30px;
  animation: from-top 2s ease;
  padding-right: 5px;
  box-sizing: border-box;
  z-index: 10000;
}
.nav h2 {
  font-weight: 400;
  padding: 0;
  width: fit-content;
  transform: rotate(270deg);
  text-align: center;
  margin: 0 0px;
}
.nav h2 a {
  text-decoration: none;
  color: rgb(0,255,200);
}
.nav h2 a:hover {
  animation: none;
  color: #0060ff;
}
.social-media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  position: fixed;
  bottom: 10px;
  left: 20px;
  animation: from-left 1s ease;
  z-index: 100;
}
.social-media a:hover {
  animation: tilt .5s ease;
}
.social-media a:nth-of-type(3) {
  fill: #1DA1F2;
}
.copyright {
  color: white;
  position: fixed;
  bottom: 10px;
  right: 50px;
}

@keyframes tilt {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: .8;
  }
  50% {
    opacity: .4;
  }
  75% {
    opacity: .9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes move {
  from {
    left: -100%;
  }
}
@keyframes from-down {
  from {
      bottom: -50%;
  }
}
@keyframes from-top {
  from {
      top: -50%;
  }
}
@keyframes from-right {
  from {
      right: -50%;
  }
}
@keyframes from-left {
  from {
      left: -100%;
  }
}
@keyframes fixed {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}