/* Home Page */
.Home {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%,-50%);
    z-index: 100;
}
.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: uppercase;
    height: 18rem;
    transform: translate(-50%,-50%);
    animation: fixed 5s ease;
}
.first,.second {
    display: block;
    color: transparent;
    line-height: 1;
    z-index: 10;
    font-family: lato,sans-serif;
    background: -webkit-linear-gradient(bottom right,#0060ff,rgb(0, 255, 200));
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}
.initial {
    font-size: 4rem;
}
.first {
    position: absolute;
    font-weight: 900;
    line-height: 1.5;
    font-size: 2rem;
    top: 30%;
    left: 10%;
    transform: translateY(-50%);
    animation: from-top 2s ease;
    text-align: center;
}
.first span:nth-child(1) {
    text-transform: uppercase;
}
.second {
    position: absolute;
    bottom: 20%;
    font-weight: 400;
    font-size: 1.7rem;
    right: 7%;
    padding: 2px;
    transform: translateY(50%);
    animation: from-down 2s ease;
}
.number-page {
    color: white;
    display: flex;
    flex-direction: column;
    width: fit-content;
    line-height: 1;
    position: absolute;
    top: -50px;
    right: 120px;
    backdrop-filter: 5px;
    color: rgba(255, 255, 255, 0.040);
    z-index: 0;
    animation: from-right .5s ease;
}
.number-pageOne {
    color: white;
    display: flex;
    flex-direction: column;
    width: fit-content;
    line-height: 1;
    position: absolute;
    bottom: 0px;
    left: 50px;
    backdrop-filter: 5px;
    color: rgba(255, 255, 255, 0.040);
    z-index: 0;
    animation: from-left .5s ease;
}
.number {
    text-align: right;
    font-weight: 900;
    font-size: 200px;
}
.page-name {
    padding-right: 20px;
    text-align: right;
    font-size: 100px;
}

/* About Page */
.About {
    height: 90%;
    width: 90%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.logo-black {
    width: 200px;
    position: fixed;
    top: -5px;
    left: -50px;
    animation: fixed 5s ease;
    z-index: 100;
}
.menu {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-row-gap: 2%;
    justify-content: space-between;
    position: fixed;
    top: 120px;
    height: 80%;
    overflow-y: scroll;
    animation: from-top 1s ease;
}
.intro {
    color: white;
    width: 100%;
    height: fit-content;
    padding: 15px;
    box-sizing: border-box;
    text-align: justify;
    border-radius: 10px;
    font-size: 1.1rem;
    backdrop-filter: blur(3px);
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    animation: fixed 2s ease;
}
.intro h3 {
    padding: 0;
    margin: 0;
    color: #ff4400;
}
.intro p {
    color: transparent;
    background: linear-gradient(to left top,rgb(0,255,200), #0099ff);
    background-clip: text;
}
.skills {
    color: white;
    width: 100%;
    height: 100%;
    padding: 15px;
    font-size: 1.1rem;
    box-sizing: border-box;
    border-radius: 10px;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    backdrop-filter: blur(3px);
    overflow-y: scroll;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 3;
    animation: fixed 2s ease;
}
.skills h3 {
    color: rgb(0, 201, 201);
    margin: 0;
    font-weight: 600;
}
.skills p span {
    display: block;
    width: fit-content;
    color: transparent;
    background: linear-gradient(to left top,rgb(0,255,200), #0099ff);
    background-clip: text;
}

/* Project Page */
.Projects {
    height: 90%;
    width: 90%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    animation: move 1s ease;
}
.project {
    display: grid;
    grid-template-columns: 360px 360px;
    justify-content: center;
    grid-gap: 25px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 120px;
    padding-bottom: 50px;
    box-sizing: border-box;
    height: 100%;
    right: 0;
    border-radius: 10px;
    overflow-y: scroll;
    color: transparent;
}
.project-cards {
    width: 100%;
    height: 200px;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    transition: .5s ease;
}
.project-cards:hover {
    color: aliceblue;
    text-shadow: 0 0 20px black;
}
.category {
    position: absolute;
    right: 10px;
    top: 15px;
    margin: 0;
    font-weight: 400;
}
.site-name {
    position: absolute;
    top: 15px;
    left: 15px;
    margin: 0;
}
.description {
    position: absolute;
    top: 30px;
    left: 15px;
    font-size: small;
}
.site-id {
    position: absolute;
    right: 15px;
    bottom: 15px;
    margin: 0;
    font-weight: 400;
}
.visit {
    text-decoration: none;
    position: absolute;
    bottom: 15px;
    left: 15px;
    border: 1px solid #ff4400;
    z-index: 100;
    color: #ff4400;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    height: 40px;
    width: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.visit:hover {
    color: aliceblue;
    background: #ff4400;
}

/* Contact Page */
.Contact {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%,-50%);
    z-index: 100;
}
.contact {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 120px;
}
.info {
    color: rgb(0, 255, 200);
    padding-left: 100px;
}
.info h2 {
    font-weight: 500;
    font-size: 1.7rem;
    padding-left: 30px;
    color: transparent;
    background: linear-gradient(to right bottom,rgb(0,255,200), #0060ff);
    background-clip: text;
    animation: fixed 5s ease;
}
.info p {
    box-sizing: border-box;
    position: relative;
}
.info p a {
    left: 45px;
    position: absolute;
    text-decoration: none;
    color: rgb(0, 255, 200);
    animation: from-left 1s cubic-bezier(1,1.74,1,.88);
}
.info p svg {
    fill: #ff4400;
}
.info p a:hover {
    color: #ff4400;
}
.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: white;
    box-sizing: border-box;
    align-items: flex-end;
}
.detail::placeholder {
    color: rgb(180, 180, 180);
}
.textarea::placeholder {
    color: rgb(180, 180, 180);
}
.detail {
    font-size: 1rem;
    color: rgb(0, 255, 200);
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    height: 47px;
    border: none;
    border-radius: 10px;
    margin-bottom: 10px;
    background: transparent;
    outline: none;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    backdrop-filter: blur(3px);
    animation: tilt 1s ease;
}
.detail:focus {
    animation: tilt .5s ease;
}
.textarea {
    font-size: 1rem;
    color: rgb(0, 255, 200);
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    height: 200px;
    border: none;
    border-radius: 10px;
    background: transparent;
    resize: none;
    outline: none;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    backdrop-filter: blur(3px);
    animation: tilt 1s ease;
}
.textarea:focus {
    animation: tilt .5s ease;
}
.send {
    margin-top: 10px;
    height: 47px;
    width: 100px;
    color: white;
    border-radius: 10px;
    font-weight: 600;
    border: none;
    font-size: 1rem;
    background: transparent;
    align-content: flex-end;
    outline: none;
    animation: blink 1s ease;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
}
.send:hover {
    background: rgb(0, 255, 200);
    color: #0060ff;
}

/* Profile Page */
.Profile {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%,-50%);
    z-index: 100;
}
.login {
    display: flex;
    flex-direction: column;
    width: 350px;
    align-items: flex-end;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.logout {
    position: fixed;
    height: 50px;
    width: 100px;
    top: 0;
    right: 20px;
    border: 1.5px solid red;
    color: red;
    border-radius: 10px;
    background: transparent;
    outline: none;
    font-size: 1rem;
    font-weight: 600;
}
.logout:hover {
    background: red;
    color: white;
}
.log {
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
    color: rgb(0, 255, 200);
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    outline: none;
    backdrop-filter: blur(4px);
}
.log:focus {
    animation: tilt .5s ease;
}
.signIn {
    height: 50px;
    width: 100px;
    color: #ff4400;
    font-size: 1rem;
    font-weight: 700;
    outline: none;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    backdrop-filter: blur(4px);
    border: none;
    border-radius: 10px;
}
.signIn:hover {
    background: #ff4400;
    color: white;
    animation: tilt .5s ease;
}
.profile {
    display: grid;
    grid-template-columns: 39% 59%;
    height: 80%;
    margin-top: 120px;
    justify-content: space-between;
}
.upload-project {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    animation: from-top .5s ease;
}
.project-form {
    height: 45px;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    outline: none;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    border: none;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    font-size: 1rem;
    font-weight: 600;
    color: rgb(0, 255, 200);
}
.project-form:focus {
    animation: tilt .5s ease;
}
.project-form-textarea {
    margin-bottom: 10px;
    padding: 10px 20px;
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    resize: none;
    outline: none;
    backdrop-filter: blur(5px);
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    border-radius: 10px;
    border: none;
    color: rgb(0, 255, 200);
    font-size: 1rem;
    font-weight: 600;
}
.project-form-textarea:focus {
    animation: tilt .5s ease;
}
.add {
    height: 45px;
    width: 200px;
    outline: none;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    border: none;
    border-radius: 10px;
    color: rgb(0, 255, 200);
    font-size: 1rem;
    font-weight: 700;
}
.add:hover {
    background: rgb(0, 255, 200);
    color: #0060ff;
}
.feeds {
    padding: 10px;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    border-radius: 10px;
    overflow: scroll;
    backdrop-filter: blur(5px);
}
.feed {
    padding: 10px;
    background: linear-gradient(to right bottom, rgba(240, 248, 255, 0.250), rgba(240, 248, 255, 0.150));
    backdrop-filter: blur(5px);
    border-radius: 10px;
    margin-bottom: 10px;
    overflow: scroll;
}
.sender-name {
    margin: 0;
    color: #0060ff;
    font-weight: 500;
}
.sender-name:nth-child(2) {
    font-weight: 450;
}
.feed p {
    margin: 0;
    color: aliceblue;
}

@media (max-width: 500px){
    .social-media a svg {
        height: 25px;
    }
    .social-media {
        height: 100px;
        left: 5px;
        bottom: 20px;
    }
    .first {
        top: 15%;
        left: 5%;
        font-size: 2.7rem;
        font-weight: 700;
    }
    .second {
        left: 10%;
        animation: from-left 2s ease;
        font-size: 2rem;
    }
    .logo {
      height: 15rem;
      top: 45%;
    }
    .number-page {
        top: -50px;
        transform: translateX(40%);
    }
    .nav h2 {
        font-size: 1.4rem;
    }
    .menu {
        grid-template-columns: 100%;
        height: 89%;
        overflow-y: scroll;
    }
    .intro{
        width: 90%;
    }
    .skills {
        width: 90%;
        height: 85%;
        overflow-y: scroll;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
    }
    .project {
        width: 100%;
        padding-right: 0;
        display: block;
        padding-left: 0px;
        color: aliceblue;
    }
    .project-cards {
        margin-bottom: 20px;
        text-shadow: 1px 1px black;
    }
    .contact {
        grid-template-columns: 100%;
        padding: 0 25px 0 0;
    }
    .contact-form {
        width: 100%;
    }
    .info {
        padding-left: 0;
    }
    .add {
        width: fit-content;
    }
  }

  @media (max-height:450px){
      .nav {
          flex-direction: row;
          right: 20px;
          top: 20px;
      }
      .first {
          left: 2%;
      }
      .second {
          font-size: 1.5rem;
          right: 2%;
      }
      .logo {
          width: 22rem;
      }
      .nav h2 {
          transform: rotate(0deg);
          margin: 10px 20px;
          font-size: large;
      }
      .menu {
          right: 0;
          top: 0;
          display: block;
          width: 80%;
          height: 100%;
      }
      .intro {
          width: 100%;
          margin-bottom: 10px;
      }
      .skills {
          width: 100%;
      }
      .project {
          display: block;
          padding-top: 20px;
        }
      .project-cards {
          width: 480px;
          height: 270px;
          float: right;
          margin-bottom: 20px;
          padding-bottom: 50px;
      }
      .contact {
            top: 50px;
      }
      .contact-form {
          top: 10%;
          left: 70%;
      }
      .textarea {
          height: 100px;
      }
      .Profile {
          overflow: scroll;
      }
      .profile {
          margin-top: 20px;
      }
  }